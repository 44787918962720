require('dotenv').config();

module.exports = {
    siteMetadata: {
        title: `AOP`,
        description: `Als ehemals offiziell beste Liveband Baden-Württembergs sind AOP auch nach all den Jahren nach wie vor ein Erlebnis. Authentisch, energisch und echt sowie mit dem Wissen, dass sich vier Akkorde noch immer gelohnt haben, gehen Stukki, Oli und Philipp Rakete und ignorieren dabei jegliches Patentrezept, das die Punkrock-Polizei vorgibt!`,
        author: `Ministerium für Punk GbR`,
    },
    plugins: [
        `gatsby-plugin-react-helmet`,
        {
            resolve: `gatsby-source-filesystem`,
            options: {
                name: `images`,
                path: `${__dirname}/src/images`,
            },
        },
        `gatsby-transformer-sharp`,
        `gatsby-plugin-sharp`,
        `gatsby-plugin-styled-components`,
        {
            resolve: `gatsby-plugin-manifest`,
            options: {
                name: `AOP Offizielle Website`,
                short_name: `aop`,
                start_url: `/`,
                background_color: `#301c3d`,
                theme_color: `#301c3d`,
                display: `standalone`,
                icon: `src/images/icon.png`,
            },
        },
        {
            resolve: 'gatsby-source-storyblok',
            options: {
                accessToken: process.env.STORYBLOK_ACCESS_TOKEN,
                homeSlug: 'home',
                version: process.env.STORYBLOK_VERSION,
                includeLinks: true,
            }
        },
        {
            resolve: `gatsby-source-podcast-rss-feed`,
            options: {
                feedURL: `https://mxgccs.podcaster.de/die-punkrockpolizei.rss`,
            },
        },
        {
            resolve: `gatsby-plugin-google-fonts`,
            options: {
                fonts: [
                    `Bebas Neue`,
                    `Open Sans\:300,400,600` // you can also specify font weights and styles
                ],
                display: 'swap'
            },
        },
        {
            resolve: `gatsby-plugin-react-svg`,
            options: {
                rule: {
                    include: /\.inline\.svg$/
                }
            }
        },
        {
            resolve: `gatsby-plugin-google-analytics`,
            options: {
                // The property ID; the tracking code won't be generated without it
                trackingId: "UA-29544828-1",
                // Defines where to place the tracking script - `true` in the head and `false` in the body
                head: false,
                // Setting this parameter is optional
                anonymize: true,
            },
        },
        {
            resolve: `gatsby-plugin-facebook-pixel`,
            options: {
                pixelId: "290855841536370",
            },
        },
        // this (optional) plugin enables Progressive Web App + Offline functionality
        // To learn more, visit: https://gatsby.dev/offline
        // `gatsby-plugin-offline`,
    ],
}
